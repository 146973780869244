<template>
  <div id="body">
  <!-- 头部 -->
    <div class="page-header">
      <!-- 导航 -->
      <TopBar class="navi" :activeIndex="'3'"/>
      <!-- bannner -->
      <CBanner :bannerCont="bannerCont"/>
    </div>
    <div class="page-container">
      <div id="product-list">
        <div class="pcard-item"
          v-for="item,index in this.productList" :key="index"
        >
          <div :class="index % 2 == 1 ? 'p-right' : 'p-left'">
            <img :src="require('../../assets/product-' + `${ item.pic }` + '.png')">
          </div>
          <div :class="index % 2 == 0 ? 't-right' : 't-left'">
            <h5>
              <router-link :to="item.url">{{ item.title }}</router-link>
            </h5>
            <p>{{ item.txt }}</p>
            <router-link :to="item.url">查看详情 >></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="product-footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
// 导航
import TopBar from './common/TopBar.vue'
// banner
import CBanner from './common/content/CBanner.vue'
// footer
import Footer from './common/Footer.vue'

export default {
  name: "Product",
  components: {
    'TopBar': TopBar,
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../assets/product-banner.png'),
        title: "产品中心",
        txt: "针对核能领域的不同需求，自主研发核心产品并提供各类技术服务和咨询服务Atlas系列，Bamboo系列，SARAX系列，MCX系列等；核电厂换料校核计算、调试技术支持、运行技术改造等",
      },
      productList: []
    }
  },
  beforeMount() {
    this.productList = this.$store.state.productList;
  },
  methods: {

  }
}
</script>

<style scoped>
div#body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-header {
  position: relative;
  top: 0;
  max-width: 1920px;
  margin: 0 auto;
}
/* 导航 */
.navi {
  position: absolute;
  width: 100%;
  z-index: 200;
}
div.page-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-container > div#product-list {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 5rem;
}
div.page-container > div#product-list > div.pcard-item  {
  overflow: hidden;
  margin-bottom: 2.5rem;
}
div.page-container > div#product-list > div.pcard-item > div.p-left,
div.page-container > div#product-list > div.pcard-item > div.p-right {
  width: 36%;
  float: left;
  transition: 0.6s all;
}
div.page-container > div#product-list > div.pcard-item > div.p-right {
  float: right;
}
div.page-container > div#product-list > div.pcard-item > div.p-left >img ,
div.page-container > div#product-list > div.pcard-item > div.p-right > img {
  width: 80%;
  display: block;
  margin: 0 auto;
}
div.page-container > div#product-list > div.pcard-item > div.t-left,
div.page-container > div#product-list > div.pcard-item > div.t-right {
  width: 60%;
  float: right;
  margin-top: 2.5rem;
}
div.page-container > div#product-list > div.pcard-item > div.t-right {
  float: left;
}
div.page-container > div#product-list > div.pcard-item > div.t-left > h5,
div.page-container > div#product-list > div.pcard-item > div.t-right > h5 {
  margin-bottom: 1.875rem;  
}
div.page-container > div#product-list > div.pcard-item > div.t-left > h5 > a,
div.page-container > div#product-list > div.pcard-item > div.t-right > h5 > a {
  font-size: 1.5rem;
  font-weight: normal;
  color: #333333;
  transition: 0.6s all;
  text-decoration: none;
  outline: none;
}
div.page-container > div#product-list > div.pcard-item > div.t-left > h5:hover > a,
div.page-container > div#product-list > div.pcard-item > div.t-right > h5:hover > a {
  color: #2784ff;
  transition: 0.6s all;
}
div.page-container > div#product-list > div.pcard-item > div.t-left > p,
div.page-container > div#product-list > div.pcard-item > div.t-right > p {
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #666666;
  margin-bottom: 2.5rem;
  text-align: justify;
	text-justify: distribute;
}
div.page-container > div#product-list > div.pcard-item > div.t-left > a,
div.page-container > div#product-list > div.pcard-item > div.t-right > a {
  transition: 0.6s all;
  display: block;
  width: 10rem;
  box-shadow: 0px 1px 11.88px 0.12px rgba(0, 47, 110, 0.2);
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2784ff;
  font-size: 0.875rem;
  color: #fff;
  text-decoration: none;
  outline: none;
}
div.page-container > div#product-list > div.pcard-item > div.t-left > a:hover,
div.page-container > div#product-list > div.pcard-item > div.t-right > a:hover {
  background: #596fd4;
  transition: 0.6s all;
}
/* 页脚 */
div.product-footer {
  position: relative;
  top: 50px;
}
</style>